type Filter = {
  search?: string;
  hours: number[];
  regions: number[];
  places: number[];
  professions: number[];
  employments: number[];
  educations: number[];
  [key: string]: string | number | number[] | undefined;
};

export const useVacancyFilters = () => {
  const activeFilters = computed(() => {
    const { query } = useRoute();
    const filters = {} as Filter;

    for (const type in query) {
      if (Array.isArray(query[type])) {
        filters[type] = query[type] as unknown as number[];
        filters[type] = filters[type].filter(
          (v) => v !== null && v.toString() !== "",
        );
      } else {
        if (query[type] && query[type] !== "" && query[type] !== null) {
          filters[type] = query[type] as string;
        }
      }
    }

    return filters;
  });

  const clearFilters = () => {
    navigateToVacancies({} as Filter);
  };

  const setFilter = (
    ids: number[] | string | number | undefined,
    type: string,
    navigate: boolean = true,
  ) => {
    clearFilters();
    applyFilter(ids, type, navigate);
  };
  const applyFilter = (
    ids: number[] | string | number | undefined,
    type: string,
    navigate: boolean = true,
  ) => {
    if (navigate)
      navigateToVacancies({
        ...activeFilters.value,
        [type]: ids,
      });
  };

  const navigateToVacancies = (filters: Filter) => {
    //TODO: /vacatures should be i18n
    navigateTo({
      path: "/vacatures",
      query: filters,
    });
  };

  //return activeFilters, but readonly (we dont want to modify it directly)
  const filters = readonly(activeFilters);

  return { filters, setFilter, clearFilters, applyFilter };
};
